<template>
  <div>
    <van-nav-bar class="nav_bar_view" left-arrow>
      <template #left>
        <img
          @click="returnPage"
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">个人资料</span>
      </template>
    </van-nav-bar>
    <ul class="list_view">
      <li class="li-photo-view">
        <span>头像</span>
        <div class="update_photo_view">
          <van-uploader :after-read="afterRead">
            <img class="photo_right_view" :src="urlPath" />
          </van-uploader>
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
      <li class="li-nick-view" @click="toNickName">
        <span class="name_view">用户昵称</span>
        <div class="ni_right_view">
          <span>{{ userInfo.name }}</span>
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
      <li class="li-nick-view" @click="selectSex">
        <span class="name_view">性别</span>
        <div class="ni_right_view">
          <span>{{ sex }}</span>
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
      <li class="li-nick-view" @click="birth">
        <span class="name_view">出生日期</span>
        <div class="ni_right_view">
          <span>{{userInfo.birthday == undefined ? "" : userInfo.birthday.split("T")[0]}}</span>
          <!-- <span>{{ceshi(userInfo.birthday)}}</span> -->
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
      <li class="li-nick-view" @click="changeMethod(1)">
        <span class="name_view">密码设置</span>
        <div class="ni_right_view">
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
      <li class="li-nick-view" @click="changeMethod(2)">
        <span class="name_view">绑定手机号</span>
        <div class="ni_right_view">
          <span>{{ userInfo.number }}</span>
          <van-icon name="arrow" size="15px" color="#CCCCCC" />
        </div>
      </li>
    </ul>
    <van-action-sheet class="action_sheet_view" v-model="show">
      <div class="sex_content_view">
        <div class="male_view" @click="male(1)">
          <p
            :class="radio === '1' ? 'male_view_select' : 'male_view_no_select'"
          >
            男
          </p>
          <!-- <van-radio-group class="female_radio_view" v-model="radio">
            <van-radio
              name="1"
              v-show="radio === '1'"
              checked-color="#1aaf5e"
            ></van-radio>
          </van-radio-group> -->
        </div>
        <div class="female_view" @click="male(2)">
          <p
            style="text-align: center"
            :class="radio === '2' ? 'male_view_select' : 'male_view_no_select'"
          >
            女
          </p>
          <!-- <van-radio-group class="female_radio_view" v-model="radio">
            <van-radio
              name="2"
              v-show="radio === '2'"
              checked-color="#1aaf5e"
            ></van-radio>
          </van-radio-group> -->
        </div>
        <div class="bottom_cancel_view" @click="closeSex">
          <span class="cancel_view">取消</span>
        </div>
      </div>
    </van-action-sheet>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择出生日期"
        :formatter="formatter"
        @confirm="birthComplete"
        @cancel="birthCancel"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { getById, upload_upImg, app2, update } from "@/service/api/index.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      userInfo: {},
      sex: "",
      show: false,
      urlPath: "",
      radio: "1",
      currentDate: "",
      dateShow: false,
      minDate: new Date(1949, 1, 1),
      maxDate: new Date(),
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload_upImg(formData).then((res) => {
        if (res.status == 200) {
          this.urlPath = app2 + "/" + res.data;
          update({ headImg: app2 + "/" + res.data }).then((res) => {});
        }
      });
    },
    returnPage() {
      this.$router.go(-1);
    },
    getUserInfo() {
      getById().then((res) => {
        this.userInfo = res.data;
        this.urlPath = res.data.headImg;
        if (this.userInfo.isSex === "DISABLE") {
          this.sex = "保密";
          this.radio = "3";
        } else if (this.userInfo.isSex === "DELETE") {
          this.sex = "女";
          this.radio = "2";
        } else {
          this.sex = "男";
          this.radio = "1";
        }
      });
    },
    //设置用户昵称
    toNickName() {
      this.$router.push("/nickName");
    },
    selectSex() {
      this.show = true;
    },
    male(option) {
      if (option === 1) {
        this.radio = "1";
        this.sex = "男";
        this.show = false;
        update({ isSex: "ENABLE" }).then((res) => {});
      } else {
        this.radio = "2";
        this.sex = "女";
        this.show = false;
        update({ isSex: "DELETE" }).then((res) => {});
      }
    },
    closeSex() {
      this.show = false;
    },
    changeMethod(option) {
      this.$router.push({
        path: "/changePwd",
        query: {
          option: option,
        },
      });
    },
    birth() {
      this.dateShow = true;
    },
    birthComplete(data) {
      let date = this.timestampToTime(data);
      update({ birthday: date }).then((res) => {
        if(res.status == 200){
          this.userInfo.birthday = date;
          this.dateShow = false;
        }
      });
    },
    birthCancel() {
      this.dateShow = false;
    },
    formatter(type, val) {
      // console.log(type,val)
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())+ "T";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =(date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds())+".000"
        ;
      return Y + M + D + h + m + s;  //年月日时分秒
    },
    ceshi(data){
      console.log(data)
      //  userInfo.birthday == "" ? "" : userInfo.birthday.split("T")[0] 
    }
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.nav_bar_view {
  width: 100%;
  height: 44px;
  background: white;
  color: #00b05a;
}
.list_view {
  width: 100%;
  max-width: 540px;
  position: fixed;
  top: 44px;
  background: red;
  .li-photo-view {
    width: 100%;
    max-width: 540px;
    height: 78px;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-left: 12px;
    }
  }
  .update_photo_view {
    display: flex;
    flex-direction: row;
    margin-right: 17px;
    align-items: center;
  }
  .photo_right_view {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    margin-right: 9px;
  }
  .li-nick-view {
    display: flex;
    flex-direction: row;
    height: 48px;
    padding-left: 12px;
    border-top: 1px solid #eeeeee;
    justify-content: space-between;
    align-items: center;
    background: white;
    .name_view {
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .ni_right_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 17px;
      span {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 8px;
        color: #999999;
      }
    }
  }
}
.action_sheet_view {
  max-width: 540px;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}
/deep/ .van-overlay {
  max-width: 540px;
  left: 50%;
  transform: translateX(-50%);
}
.male_view_select {
  width: 100%;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00b05a;
}
.male_view_no_select {
  width: 100%;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
  color: #333333;
}
.male_view,
.female_view {
  width: 100%;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #e5e5e5;

  .female_radio_view {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    justify-content: center;
  }
}
.bottom_cancel_view {
  width: 100%;
  height: 57px;
  text-align: center;
  line-height: 57px;
  border-top: 7px solid #f5f5f5;
  display: flex;
  justify-content: center;
}
.cancel_view {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>